import React, { KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { globalState } from 'shared/foreground/models';
import background from 'shared/foreground/portalGates/toBackground';
import { updateImportEmailAddresses } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/import';

import Button from './Button';
import Portal from './Popovers/Portal';
import styles from './UpdateImportEmailAddress.module.css';

interface DialogProps {
  setUpdateEmailDialogIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  library?: boolean;
  redActionButton?: boolean;
  darkRedActionButton?: boolean;
}

export const UpdateImportEmailAddress = ({
  setUpdateEmailDialogIsOpen,
  library = true,
  redActionButton = false,
  darkRedActionButton = false,
}: DialogProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const profile = globalState(useCallback((state) => state.client.profile, []));

  const email = library ? profile?.custom_library_email : profile?.custom_feed_email;
  const emailName = email?.substring(0, email.indexOf('@'));
  const emailAt = email?.slice(email.indexOf('@'));

  const [newEmailName, setNewEmailName] = useState(emailName || '');
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [customEmailExists, setCustomEmailExists] = useState(false);

  const newEmailHasOneOrLessChars = useMemo(() => newEmailName.length <= 1, [newEmailName]);

  const updateImportEmailAddressCallback = useCallback(async () => {
    await updateImportEmailAddresses(newEmailName, { userInteraction: 'click' });
  }, [newEmailName]);

  const cancelAction = useCallback(() => {
    setUpdateEmailDialogIsOpen(false);
  }, [setUpdateEmailDialogIsOpen]);

  const keyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target !== event.currentTarget) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();

      if (event.key === 'Escape') {
        cancelAction();
      }
    },
    [cancelAction],
  );

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();
  }, [inputRef]);

  useEffect(() => {
    const checkEmail = async (newEmailName: string) => {
      const nonceExists = await background.checkIfNonceExists(newEmailName);

      if (nonceExists && newEmailName !== emailName) {
        setCustomEmailExists(true);
      } else {
        setCustomEmailExists(false);
      }

      if (
        customEmailExists === false &&
        newEmailHasOneOrLessChars === false &&
        newEmailName !== emailName
      ) {
        setSaveButtonDisabled(false);
      } else {
        setSaveButtonDisabled(true);
      }
    };

    checkEmail(newEmailName);
  }, [newEmailName, customEmailExists, emailName, newEmailHasOneOrLessChars]);

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target === e.currentTarget) {
        cancelAction();
      }
    },
    [cancelAction],
  );

  return (
    <Portal id="dialog--personalize-email-address">
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={styles.dialogWrapper} onClick={onClick} tabIndex={0} onKeyDown={keyDown}>
        <div className={styles.dialogBox}>
          <div className={styles.text}>
            <h1 className={styles.title}>Personalize email addresses</h1>
            <p className={styles.subtitle}>
              Replace your randomized alias to make your Library and Feed email addresses easier to
              remember.
            </p>
            <p className={styles.subtitle}>
              Your original alias ({profile?.original_library_email}) will remain active.
            </p>
          </div>
          <div className={styles.inputWithHint}>
            <label htmlFor="emailInput" className={styles.inputLabel}>
              Forward email
            </label>
            <div className={styles.emailInputContainer}>
              <input
                autoFocus
                className={styles.emailInput}
                value={newEmailName}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const filteredValue = inputValue.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

                  setNewEmailName(filteredValue);

                  if (newEmailHasOneOrLessChars) {
                    setSaveButtonDisabled(true);
                  }
                }}
                id="emailInput"
                aria-labelledby="forward email"
                type="email"
                autoComplete="off"
                ref={inputRef}
                onKeyDown={(event) => {
                  if (event.key === 'Escape') {
                    cancelAction();
                  }
                  if (event.key === 'Enter') {
                    if (!saveButtonDisabled) {
                      updateImportEmailAddressCallback();
                      cancelAction();
                    }
                  }
                }}
              />
              <span className={styles.hint}>{emailAt}</span>
            </div>
            <span className={styles.inputWarning}>
              Warning: A generic alias might be vulnerable to unsolicited emails.
            </span>
            {customEmailExists && (
              <span className={styles.nameExistsError}>Email address already in use</span>
            )}
            {newEmailHasOneOrLessChars && (
              <span className={styles.nameExistsError}>Email can&apos;t be less than 2 characters</span>
            )}
          </div>
          <div className={styles.buttons}>
            <Button className={styles.cancelButton} onClick={cancelAction} variant="secondary">
              Cancel
            </Button>
            <Button
              disabled={saveButtonDisabled}
              className={[
                redActionButton ? styles.redButton : '',
                darkRedActionButton ? styles.darkRedButton : '',
              ]
                .filter(Boolean)
                .join(' ')}
              onClick={() => {
                updateImportEmailAddressCallback();
                cancelAction();
              }}
              variant="primary"
            >
              <span className={styles.buttonText}>Save</span>
            </Button>
          </div>
        </div>
      </div>
    </Portal>
  );
};
